import Block from '../block'

export default class BlockImage extends Block {
	static title = 'block-image'

	constructor($el) {
		super($el)

		if (this.$el.hasModifier('popup')) {
			// only the images with caption position inside needs this js
			if (this.$el.get(0).className.search('inside')) {
				this.images = this.$el.findElement('item')

				this.images.each((index, image) => {
					const button = $(image).find('.block-image__toggle')

					if (button.length > 0) {
						button.on('click', () => {
							$(image).find('.block-image__content').toggleClass('hidden')
							// toggle hidden class for both the plus and minus icon
							// because the minus has the hidden class on load and the plus icon does not, only one of the two should be visible at all times
							button.find('i').toggleClass('hidden')
						})
					}
				})
			}
		}

		if (this.$el.hasModifier('slidein')) {
			// only the images with caption position inside needs this js
			if (this.$el.get(0).className.search('inside')) {
				this.images = this.$el.findElement('item')

				this.images.each((index, image) => {
					const button = $(image).find('.block-image__toggle')

					if (button.length > 0) {
						button.on('click', () => {
							$(image)
								.find('.block-image__content')
								.toggleClass('block-image__content--active')
							$(image).find('.block-image__text').toggleClass('hidden')
							// toggle hidden class for both the plus and minus icon
							// because the minus has the hidden class on load and the plus icon does not, only one of the two should be visible at all times
							button.find('i').toggleClass('hidden')
						})
					}
				})
			}
		}
	}
}
